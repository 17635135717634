/*

logo blue A : #3490D8
logo blue B : #23A3D8
logo text : #1080D7
logo subtext: #d7cdc1
logo socials: #958676
background : #061A2B

*/

.metallic {
  background: #061A2B;

  background-size: 400% 400%;
  color: #d7cdc1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
}

body {

  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Light red background */
.content {
  background: #030E17;
  flex: 1;
  color: #1080D7;
}

.footer {
  background: #061A2B;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2), 0 -1px 3px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  text-align: center;
  color: #d7cdc1 !important;
}

.top-bar {
  position: sticky;
  top: 0px;
  z-index: 1000;
  width: 100%;
  min-height: 5rem;
  color: #d7cdc1;
  margin: 0 !important;
  padding: 0 !important;
}

@media (max-width: 768px) {
  .top-bar {
    position: static;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.table-component {
  display: flex;
  flex-direction: column;
}

.section-title {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 0;
}

.section-divider {
  width: 100%;
  border-top: 2px solid #000; /* Adjust color and thickness as needed */
  margin-top: 8px;
  margin-bottom: 24px;
}

.search-bar {
  margin-bottom: 24px;
}

.social-media-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.social-media-icons img {
  transition: transform 0.3s ease;
}

.social-media-icons img:hover {
  transform: scale(1.1);
}

@media (max-width: 576px) {
  .modal-content {
    padding: 15px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-label, .form-control {
    font-size: 14px;
  }

  .form-control {
    padding: 10px;
  }

  .d-flex {
    flex-direction: column;
  }

  .d-flex .btn {
    margin-bottom: 10px;
  }
}

.copyright {
  font-size: 14px;
  margin-top: 10px;
}

.custom-nav .nav-link-item {
  height: 6em; /* Make the link the same height as the navbar */
  min-width: 8em;
  min-height: 5em;
  padding: 0 15px; /* Adjust padding to control the horizontal size */
  color: #1080D7; /* Default text color */
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transition */
  display: flex;
  align-items: center; /* Vertically center the text */
  justify-content: center; /* Horizontally center the text */
}

.custom-nav .nav-link-item:hover,
.custom-nav .nav-link-item:focus {
  background-color: #1080D7; /* Background color on hover */
  color: #ffffff; /* Text color on hover */
  border-color: #1080D7; /* Border color on hover */
}

.custom-nav .nav-link-item:active,
.custom-nav .nav-link-item.active {
  background-color: #0a3c63; /* Slightly darker background color when pressed */
  color: #ffffff !important; /* Text color when pressed */
  border-color: #0a3c63; /* Border color when pressed */
}

.media-icon {
  padding-right: 3em;
}

.language-switcher {
  padding-left: 1em;
}

@media (max-width: 992px) {
  .navbar-nav {
    padding: 0 !important;
    margin: 0 !important;
  }

  .social-media-icons {
    margin-bottom: 1em;
  }

  .media-icon {
    padding-right: 0em;
    padding-bottom: 1em;
  }

  .language-switcher {
    padding-left: 0em;
  }

  .custom-nav .nav-link-item {
    height: auto;
    width: 100%;
    min-height: 3em;
    padding: 10px 0;
    text-align: center;
  }

  .custom-nav .nav-link-item.active {
    background-color: #1080D7;
    color: #d7cdc1;
    border: none; /* Remove borders on active link */
  }

  .custom-nav .nav-link-item:hover,
  .custom-nav .nav-link-item:focus {
    background-color: #1080D7;
    color: #d7cdc1;
    border: none;
  }
}

.back-arrow, .back-arrow-icon {
  color: #1173bd !important;
  transition: color 0.3s ease;
}

.back-arrow:hover, .back-arrow-icon:hover {
  color: #115385 !important; /* Change color on hover if desired */
}


.back-button {
  background-color: #1173bd !important; /* Button background color */
  color: #d7cdc1 !important; /* Text color */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Shadow */
}

.back-button:hover {
  background-color: #14639f; /* Slightly lighter color on hover */
}

.contactus-button {
  position: fixed;
  bottom: 10em;
  right: -5px;
  z-index: 1050; /* Ensure it appears on top of other elements */
  background-color: #1080D7 !important; /* Button background color */
  color: #d7cdc1; /* Text color */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Shadow */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Button padding */
  font-size: 16px; /* Font size */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition */
  width: 200px;
  height: 64px;
}

.contactus-button:hover {
  background-color: #1370b8; /* Slightly lighter color on hover */
}

.contactus-button:focus, .contactus-button:active {
  background-color: #1163a2; /* Darker color when focused or active */
  outline: none; /* Remove default outline */
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5); /* Stronger shadow when active */
}

.contact-us {
  color: #d7cdc1;
}

.calendar-header {
  text-align: center;
  padding: 10px;
}

.view-buttons {
  margin-top: 10px;
}

.view-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .calendar-header h2 {
    font-size: 1.5rem;
  }

  .view-buttons button {
    margin: 5px;
    padding: 8px 16px;
  }
}


/* Leaderboard card with shadow for 3D effect */
.leaderboard-card {
  background-color: #061A2B !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  margin: 0;
}

/* Prominent title styling */
.leaderboard-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1080D7;
  background-color: #061A2B;
  padding: 15px;
  text-align: center;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.tournament-card-title {
  color: #1080D7 !important;
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 25px;
}

/* Header row color */
.table-header {
  background-color: #061A2B !important;
  color: #d7cdc1 !important;
}

/* Even and odd row colors for better contrast */
.even-row {
  background-color: #0C3457 !important; /* Lighter red background */
}

.odd-row {
  background-color: #061A2B !important; /* #d7cdc1 background */
}

.leaderboard {
  background-color: #072c48 !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.table-container {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  color: #d7cdc1 !important;
  background-color: #061A2B!important;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1);
}

.table-container td {
  color: #d7cdc1 !important;
  border: 0px;
}

/* Custom scrollbar styling */
.table-container::-webkit-scrollbar {
  width: 8px;
}

.table-container::-webkit-scrollbar-track {
  background: #061A2B;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #0e4c7c;
  border-radius: 10px;
  border: 2px solid #0e4c7c; /* Adds padding between scrollbar and thumb */
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #0f5285;
}

.timeline-container {
  position: relative;
  padding: 20px 0;
  background-color: #061A2B !important;
  color: #d7cdc1 !important;
}


.tournament-dot {
  background-color: #1080D7 !important;
} 


.tournament-line {
  background-color: #1080D7 !important;
} 

.timeline-item {
  position: relative;
  width: 100%;
  padding: 20px 0;
  color: #1163a2 !important;
}

.timeline-item.left {
  text-align: right;
}

.timeline-item.right {
  text-align: left;
}

.tournament-card {
  margin: 0 auto;
  width: 80%;
  transition: transform 0.2s;
  background-color: #061A2B !important;
  color: #d7cdc1 !important;
  box-shadow: 0 4px 4px rgba(23, 23, 23, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 0px;
}

.tournament-card-title {
  color: #1080D7 !important;
  border: 0px;
}

.tournament-card:hover {
  transform: scale(1.05);
}

.tournament-season {
  color: #d7cdc1 !important;
  padding: 15px;
}

/* Line in the middle */
.timeline-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  z-index: 0;
}

@media (max-width: 768px) {
  .tournament-season {
    margin-bottom: 10px;
    text-align: left;
  }
}

.copyright-page p, .copyright-page a {
  color: #d7cdc1 !important;
}

/* Custom scrollbar styling */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #061A2B;
}

body::-webkit-scrollbar-thumb {
  background-color: #0e4c7c;
  border-radius: 10px;
  border: 2px solid #0e4c7c; /* Adds padding between scrollbar and thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #0f5285;
}

@font-face {
  font-family: 'High Speed';
  src: url('./fonts/High Speed.ttf') format('truetype'); /* Adjust the path accordingly */
}


.search-bar {
  min-width: 650px;
}

/* Responsive design */
@media (max-width: 768px) {

  .search-bar {
    min-width: 300px;
  }
}

/* font smaller for toggle text */
@media (max-width: 576px) {

  .search-bar {
    min-width: 260px;
  }
}
