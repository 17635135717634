/* Container for the news ticker */
.news-ticker-container {
    background-color: #1163a2;
    color: white;
    width: 100%;
    overflow: hidden;
    top: 0;
    z-index: 1000;
  }
  
  /* The ticker itself */
  .news-ticker {
    display: inline-block;
    white-space: nowrap;
    animation: scroll-left 60s linear infinite;
  }
  
  /* Style each news item */
  .news-item {
    display: inline-block;
    padding: 0px;
    font-size: 16px;
  }
  
  /* Style the dot separator between news items */
  .dot-separator {
    padding: 0 60px;
    font-size: 20px;
  }

  /* Remove default link styles for news links */
.news-item-link {
    color: white;
    text-decoration: none;
}

.news-item-link:hover {
    text-decoration: underline; /* Optional: Add underline on hover */
}
  
  /* Animation to scroll the text */
  @keyframes scroll-left {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  