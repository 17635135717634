/* Loading overlay to prevent page flash */
.loading-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading-circle {
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top: 6px solid #1080D7;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-top: 6em;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  