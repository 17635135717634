
/*.homepage {
    padding: 50px 0;
  }
  
  .homepage h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .homepage p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  .homepage .container-fluid {
    padding: 0 30px;
  }
  
/* Text section should still be aligned left */
.homepage .text-section {
    text-align: left;
}

/* Ensure images are responsive */
.homepage .img-fluid {
    max-width: 100%;
    height: auto;
    padding: 20px; /* Adjust if necessary */
}

/* Optional: Ensure form labels remain left-aligned */
.homepage form label {
    text-align: left;
    display: block;
}

/* Optional: Add form centering */
.homepage form {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.homepage p, .homepage li {
    color: #d7cdc1;
    font-size: 16px;
}