.leaderboards-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .toggle-text-container {
    width: 50%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .toggle-text {
    cursor: pointer;
    font-size: 1.5rem;
    color: #1080D7; /* Default text color */
    transition: color 0.3s ease;
  }
  
  .toggle-text:hover {
    color: #d7cdc1; /* Color on hover */
  }
  
  .toggle-text.active {
    color: #d7cdc1; /* Highlighted when active */
    font-weight: bold;
  }
  
  .leaderboards-separator {
    font-size: 1.5rem;
    color: #1080D7; /* Separator color */
  }
  
  .leaderboards-hr {
    width: 50%;
    margin: 20px 0;
    color: #1080D7; /* Separator color */
  }
  
  .leaderboards-content {
    width: 100%;
    text-align: center;
  }


  .leaderboards-search-bar  {
    min-width: 650px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    hr {
      width: 80%;
    }
  
    .toggle-text-container {
      width: 80%;
    }

    .leaderboards-search-bar {
      min-width: 300px;
    }
  }
 
  .dot {
    padding-top: 5px;
  }

  /* font smaller for toggle text */
@media (max-width: 576px) {
    .toggle-text {
        font-size: 1.2rem;
    }

    .dot {
      padding-top: 0px;
    }

    .leaderboards-search-bar {
      min-width: 260px;
    }
}
