/* Base calendar styles */
.responsive-calendar {
  overflow: hidden;
  border: 2px solid #061A2B;
  border-radius: 8px;
  width: 100%;
  color: #d7cdc1 !important;
  background-color: #061A2B !important;
  height: auto; /* Set auto height based on viewport */
  border-color: #d7cdc1 !important;
}

.tui-full-calendar-layout {
  color: #d7cdc1 !important;
  border-color: #d7cdc1 !important;
  background-color: #061A2B !important;
}

.event-details-card {
  background-color: #061A2B !important;
  color: #d7cdc1 !important;
}

.event-details-card-container .modal-content {
  background-color: #061A2B !important;
}

.event-details-card-title {
  color: #1080D7 !important;
}



.tui-full-calendar-near-month-day {
  margin-top: -20px !important;
}

.tui-full-calendar-weekday-schedule-title {
  color: #d7cdc1 !important;
}

.tui-full-calendar-weekday-schedule-bullet {
  background: #1080D7 !important;
}

/* Display event blocks with a minimum height of 100px */
.tui-full-calendar-weekday-schedule {
  min-height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d7cdc1;
  background-color: #1080D7 !important;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;

  font-size: 12px;
}

.tui-full-calendar-weekday-schedule-title > p {
  word-wrap: break-word !important;
  display: inline-block !important;
}

/* Increase text size and cell padding */
.tui-full-calendar-time-date, .tui-full-calendar-title {
  font-size: 1.2rem; /* Increase event titles and time font size */
}

.tui-full-calendar-weekday-grid-date, .tui-full-calendar-weekday-grid-time {
  font-size: 0.8rem; /* Increase weekday date size */
}

.tui-full-calendar-weekday-grid {
  padding: 1rem; /* Increase padding of the grid */
}

.tui-full-calendar-time-schedule-content {
  font-size: 1.1rem; /* Increase font size for schedule content */
}

/* Ensure that event text wraps within the cell */
.tui-full-calendar-time-schedule-content, 
.tui-full-calendar-time-date,
.tui-full-calendar-title {
  #d7cdc1-space: normal !important; /* Allow text to wrap */
  word-wrap: break-word !important; /* Ensure long words break if necessary */
  overflow: hidden !important; /* Hide any overflowing text */
  text-overflow: ellipsis !important; /* Add ellipsis for any overflowing text */
  display: inline-block !important;
}

/* Small screens (mobile) */
@media (max-width: 576px) {
  .responsive-calendar {
    height: 700px; /* Adjust height for mobile screens */
  }

  .tui-full-calendar-weekday-grid-date, .tui-full-calendar-weekday-grid-time {
    font-size: 0.9rem; /* Slightly smaller font for mobile */
  }
}

/* Medium screens (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
  .responsive-calendar {
    height: 700px; /* Adjust height for medium screens */
  }

  .tui-full-calendar-weekday-grid-date, .tui-full-calendar-weekday-grid-time {
    font-size: 1.0rem;
  }
}

/* Large screens (laptops/desktops) */
@media (min-width: 769px) and (max-width: 992px) {
  .responsive-calendar {
    height: 700px; /* Adjust height for large screens */
  }

  .tui-full-calendar-weekday-grid-date, .tui-full-calendar-weekday-grid-time {
    font-size: 1.0rem;
  }
}

/* Extra-large screens */
@media (min-width: 993px) {
  .responsive-calendar {
    height: 700px; /* Adjust height for extra-large screens */
  }

  .tui-full-calendar-weekday-grid-date, .tui-full-calendar-weekday-grid-time {
    font-size: 1.2rem; /* Increase the text size further for larger screens */
  }
}

.tui-full-calendar-near-month-day,
.tui-full-calendar-weekday-grid-date,
.tui-full-calendar-month-dayname-item > span,
.tui-full-calendar-holiday-sat,
.tui-full-calendar-holiday-sun {
  color: #d7cdc1 !important;
  border-color: #d7cdc1 !important;
}

.tui-full-calendar-weekday-border {
  border-color: #d7cdc1 !important;
}

.tui-full-calendar-weekday-schedule.tui-full-calendar-weekday-schedule-time .tui-full-calendar-weekday-schedule-title {
  padding-left: 9px;
  text-wrap: wrap;
  max-height: 50px;
}

.tui-full-calendar-extra-date .tui-full-calendar-weekday-grid-date {
  color: #67625c !important;
}

.tui-full-calendar-weekday-grid-date-decorator {
  background: #1080D7 !important;
}